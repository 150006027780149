import { DatePicker, Input, Select } from "antd";
import { AttachMoneyOutlined, CurrencyRupee } from "@mui/icons-material";
import TextArea from "antd/es/input/TextArea";
import NumberFormat from "../utils/NumberFormat";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { PercentageOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import CurrencyIconRenderer from "./CurrencyIconRendered";
import { useEffect } from "react";

type InputProps = {
    image? : React.ReactNode,
    type : string,
    placeholder :string,
    value? :any,
    defaultValue?: string[],
    onChange?: (e:any)=>(void)
    onDeselect?:(e:any)=>void,
    onInputKeyDown?:(e:any)=>void,
    index?:number,
    currency?:boolean,
    percent?:boolean,
    options?:{label:string,value:string | number}[],
    style?:any,
    disabled?:boolean
}


const InputBox = (props:InputProps)=>{

    const myCurrency = useSelector((state: RootState) => state.calculator.activeScreen?.currency || "USD");


    useEffect(()=>{
        if(props?.type === "date" && !props?.value){
            if(props.onChange) props.onChange({target:{value:dayjs().format("YYYY-MM-DD")}});
        }
    },[])
 

    return(
        <>
        {
            (props.type === "text" || props.type === "password") &&
            <>
            <div className="formInputCnt">
            {
                props.image &&
                props.image
            }
            
        {
            props.type === "text" &&
            <Input 
            className="mainInput" 
            placeholder={props.placeholder} 
            autoComplete="none" 
            value={props.value} 
            onChange={props.onChange} />
        }
        {
            props.type === "password" &&
            <Input.Password 
            className="mainInput" 
            placeholder={props.placeholder} 
            autoComplete="none"
            value={props.value}
            onChange={props.onChange}
            />
        }
 
        </div>
            </>
        }
         {
            props.type === "string" &&
            <>
            <label className="label">{props.placeholder}</label>
            <div className="formInputCnt" style={{height:"35px"}}>
            <Input 
            type="text"
            className="mainInput"
            autoComplete="none" 
            value={props.value} 
            onChange={props.onChange} />
            </div>
            </>
        }
        {
            props.type === "textarea" &&
            <>
            <label className="label">{props.placeholder}</label>
            <div className="formInputCnt" style={{height:"auto"}}>
            <TextArea 
            className="mainInput"
            rows={3}
            autoComplete="none" 
            value={props.value} 
            onChange={props.onChange}
            />
            </div>
            </>
        }
        {
            props.type === "Cnumber" &&
            <>
            <label className="label">{props.placeholder}</label>
            <div className="formInputCnt" style={{height:"35px"}}>
                {
                    props.currency&&
                    <CurrencyIconRenderer type={myCurrency} />
                }
            <Input 
            type="text"
            className="mainInput"
            autoComplete="none" 
            value={
                myCurrency === "INR" ?  NumberFormat.toINDstandard(props.value) : NumberFormat.toUSstandard(props.value)
            }
            onChange={(e)=>{
                e.target.value = NumberFormat.toNumber(e.target.value);
                if(props.onChange)
                props.onChange(e);
            }}
            style={props.style}
            disabled={props.disabled}
             />
            {
                props.percent &&
                <PercentageOutlined className="percentIcon"  />
            }
            </div>
            </>
        }
        {
            
            props.type === "Cnumber2" &&
            <>
            <label className="label">{props.placeholder}</label>
            <div className="formInputCnt" style={{height:"35px"}}>
            <Input 
            type="text"
            className="mainInput"
            autoComplete="none" 
             value={
                    myCurrency === "INR" ? NumberFormat.toINDstandard(props.value) : NumberFormat.toUSstandard(props.value)
                    }
            onChange={(e)=>{
                e.target.value = NumberFormat.toNumber(e.target.value);
                if(props.onChange)
                props.onChange(e);
            }} />
            {
                props.percent &&
                <PercentageOutlined className="percentIcon"  />
            }
            </div>
            </>
        }
        {
            props.type === "select" &&
            <>
            <label className="label">{props.placeholder}</label>
            <div className="formInputCnt" style={{height:"35px"}}>
            <select
            className="mainInput"
            onChange={(e)=>{
                props.onChange && props.onChange(e);
            }}
            value={props.value}
            >
                {
                    props.options?.map((elem,index)=> (
                        <option key={index} value={elem.value}>{elem.label}</option>
                    ))
                }
            </select>
            </div>
            </>
        }
        {
            props.type === "multiSelect" &&
            <>
             <label className="label">{props.placeholder}</label>
            <div className="formInputCnt" style={{
                minHeight:"35px",
                width:"auto",
                height:"auto"
                }}>

                <Select
                mode="multiple"
                className="mainInput"
                style={{  }}
                placeholder="Please select"
                defaultValue={props.defaultValue || []}
                onChange={props.onChange}
                dropdownStyle={{
                    zIndex:101,
                    width:"100%"
                }}
                size='large'
                bordered={false}
                options={props.options}
                onDeselect={props.onDeselect}
                onInputKeyDown={props.onInputKeyDown}
                />
                

            </div>
            </>
        }
        {
            props?.type === "date" &&
            <>
            <label className="label">{props.placeholder}</label>
            <div className="formInputCnt" style={{height:"35px"}}>
            <DatePicker 
            className="mainInput"
            style={{position:"relative"}}
            popupStyle={{zIndex:10000}}
            value={dayjs(props.value,"YYYY-MM-DD") } 
            onChange={(date, dateString) => {

                let prep = !dateString ? dayjs().format("YYYY-MM-DD") : dateString;
      
                if(props.onChange) props.onChange({target:{value:prep}});
              }} />
            </div>        
            </>
        }
        </>
    )

}

export default InputBox;