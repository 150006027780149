import { useContext,useState,useEffect,useRef } from 'react';

import logout_logo from "../svg/logout.svg";
import { GlobalContextType } from '../@types/user';
import { useNavigate } from "react-router-dom";
import { globalData } from '../context/globalContext';
import { CloseOutlined, MenuOutlined, RightOutlined } from '@ant-design/icons';
import { Alert, Button } from 'antd';
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { logout } from '../redux/userSlice';
import { Axios } from '../Axios';
import ModalBox from './modal';
import Flynaut from './branding/Flynaut';

import CalculateIcon from '@mui/icons-material/Calculate';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import MenuIcon from '@mui/icons-material/Menu';
import { Modal } from '@mui/material';
import InputBox from './input';

import LockResetIcon from '@mui/icons-material/LockReset';
import VerifiedIcon from '@mui/icons-material/Verified';

const Header = ()=>{

    const { activeTab, setActiveTab } = useContext(globalData) as GlobalContextType;
    const user = useSelector((state: RootState) => state.user.user );
    const [ mobMenuState, setMobMenuState ] = useState<boolean | null>(null); // for opening or closing menu
    const [ signoutConfirm, setSignoutConfirm ] = useState(false);
    const menuHolder = useRef<any>();
    const innerMenuHolder = useRef<any>();
    const [dropDownMenu,setDropDownMenu] = useState(false);
    const [openChangePassword, setOpenchangePassword] = useState(false);
    const [currPass,setCurrPass] = useState("");
    const [newPass,setNewPass] = useState("");
    const [cnfPass,setCnfPass] = useState("");
    const [resetSuccess,setResetSuccess] = useState(false);
    const [error,setError] = useState("");
    const [load,setLoad] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const submitChangePassword = ()=>{

        setLoad(true);

        Axios.post(`/api/v1/change-password`,{
            currPass,
            newPass,
            cnfPass
        },{
            headers:{
                Authorization:user?.token
            }
        })
        .then(result=> {

            setResetSuccess(true);
            setCurrPass("");
            setNewPass("");
            setCnfPass("");

        })
        .catch(err=>{
            setError(err.response.data.message);
        })
        .finally(()=>{
            setLoad(false);
        })
    }


    // signout function
    const signOut = ()=>{

        Axios.post(`/api/v1/signout`,{},{
            headers:{
                Authorization:user?.token
            }
        })
        .then(result=> {

            dispatch( logout() );

        })
        .catch(err=>{

        })
    }


    // hiding active mobile menu when switched to pc mode
    useEffect(()=>{

        window.addEventListener("resize",resizeFunc);

        return ()=>{
            window.removeEventListener("resize",resizeFunc);
        }

    },[]);


    const resizeFunc = ()=>{
        if(window.innerWidth >=800){
            setMobMenuState(false);
        }
    }

    // hiding or showing menu based on mobMenuState value.
    useEffect(()=>{
        if(mobMenuState === null) return;

        if(mobMenuState && menuHolder.current && innerMenuHolder.current){
            menuHolder.current.style.display = "flex";
            setTimeout(()=>{
                innerMenuHolder.current.style.transform = "translateX(0)";
            },100);
        }
        else if(!mobMenuState && menuHolder.current && innerMenuHolder.current){
            innerMenuHolder.current.style.transform = "translateX(-100%)";
            setTimeout(()=>{
                menuHolder.current.style.display = "none";
            },500);
        }

    },[mobMenuState]);
    

    // Redirection function.
    const redirectHandler = (num:Number)=>{
       
        setActiveTab(num);

        if(num === 0){
            navigate("/home");
        }
        else if(num === 1){
            navigate("/histories");
        }
        else if(num === 2){
            navigate("/users");
        }

        
    }

    return(
        <>
        <div id="header">
            {/* <img className="logo" src="/images/cco-small.png" alt="brand_logo" /> */}

            <Button className='menuBtn' onClick={()=> setMobMenuState(true)}>
                    <MenuIcon />
            </Button>


            <Flynaut className="logo" />

            <div className="pcLinks">
                <ul>
                    <li className={activeTab == 0 ? `active` : ''} onClick={()=> redirectHandler(0) }>
                        <CalculateIcon />
                        QL inputs
                    </li>
                    <li className={activeTab == 1 ? `active` : ''} onClick={()=> redirectHandler(1) }>
                        <ManageHistoryIcon />
                        Histories
                    </li>
                   {
                    user?.role === 2 &&
                    <li className={activeTab == 2 ? `active` : ''} onClick={()=> redirectHandler(2) }>
                        <PeopleAltIcon />
                        Users
                    </li>
                   }
                </ul>

                <div className='authUserDisplayCnt'>
                    <div className='authUserDisplayTop' onClick={()=> setDropDownMenu(prev=> !prev)}>
                        <p>{user?.name}</p>
                        <KeyboardArrowDownIcon />
                    </div>
                    {
                        dropDownMenu &&
                        <div className='authUserDisplayBot'>
                            <Button type='text' className='authUserDisplayBotTile' onClick={()=> {setError("");setResetSuccess(false);setOpenchangePassword(true)} }>
                                <VpnKeyIcon />
                                <p>Change Password</p>
                            </Button>
                            <Button type='text' className='authUserDisplayBotTile logoutRedBtn' onClick={()=> setSignoutConfirm(true)}>
                                <LogoutIcon />
                                <p>Logout</p>
                            </Button>
                        </div>

                    }
                   
                </div>

                {/* <MenuOutlined className='menuBtn' onClick={()=> setMobMenuState(true)  } /> */}
        
            </div>            

        </div>

        <div id='mobMenuCnt' ref={menuHolder}>
            <div id='mobMenuPanel' ref={innerMenuHolder}>
            <div className='mobMenuPanelTop'>
                <img className="mobmenulogo" src="/images/cco-small.png" alt="brand_logo" />
                <img className="logoutBtn" src={logout_logo} alt="logout_btn" onClick={()=> setSignoutConfirm(true)} />
            </div>

            <Button 
            className={`mobMenuItem ${activeTab == 0 && 'mobItemActive'}`}
            onClick={()=> redirectHandler(0) }
            type='text'>
                <CalculateIcon />
                QL Inputs
            </Button>
            <Button 
            className={`mobMenuItem ${activeTab == 1 && 'mobItemActive'}`}
            onClick={()=> redirectHandler(1) }
            type='text'>
                <ManageHistoryIcon />
                Histories
            </Button>
            <Button 
            className={`mobMenuItem ${activeTab == 2 && 'mobItemActive'}`}
            onClick={()=> redirectHandler(2) }
            type='text'>
                <PeopleAltIcon />
                Clients
            </Button>

            </div>

            <div id='mobMenuClose' onClick={()=> setMobMenuState(false) } >
                
            </div>

        </div>
        {
            signoutConfirm &&
            <ModalBox
            open={signoutConfirm}
            onClose={()=> setSignoutConfirm(false)}
            header='Confirm !'
            message='Are you sure you want to Log out !'
            onOk={()=>{
                signOut()
            }}
            onCancel={()=> setSignoutConfirm(false) }
            />
        }
        {
            <Modal
            open={openChangePassword}
            onClose={()=> setOpenchangePassword(false)}
            sx={{
                alignItems:"center",
                justifyContent:"center",
                display:"flex"
            }}
            >
                <>
                <div className="modalPopUp" style={{maxWidth:"300px",margin:"0 15px 0 15px"}}>

                    <CloseOutlined
                    className="popupClose"
                    onClick={()=> setOpenchangePassword(false)}
                    />
                        <div className="mpuTop">

                            {
                                !resetSuccess &&
                                <div>
                                
                                {
                                    error &&
                                    <Alert 
                                    type='error' 
                                    message={error}
                                    showIcon={true} 
                                    style={{fontSize:"12px",height:"auto",margin:"0 0 5px 0"}} />
                                }
                                
                                <div
                                style={{
                                    display:"flex",
                                    alignItems:"center",
                                    justifyContent:"center",
                                    margin:"0 0 30px 0",
                                    color:"gray"
                                }}
                                >
                                    <LockResetIcon sx={{fontSize:"75px"}} />
                                </div>
                           
                                    <InputBox
                                    type="password"
                                    placeholder={"current password"}
                                    value={currPass}
                                    onChange={(e)=> {
                                        setCurrPass(e.target.value);
                                    }}
                                        />
                                    <br />

                                    <InputBox
                                    type="password"
                                    placeholder={"new password"}
                                    value={newPass}
                                    onChange={(e)=> {
                                        setNewPass(e.target.value);
                                    }}
                                        />

                                    <InputBox
                                    type="password"
                                    placeholder={"confirm password"}
                                    value={cnfPass}
                                    onChange={(e)=> {
                                        setCnfPass(e.target.value);
                                    }}
                                        />
                            </div>
                            }
                            {
                                resetSuccess &&
                                <div style={{display:"flex",flexDirection:"column",alignItems:"center",gap:"25px"}}>
                                    <p style={{textAlign:"center"}}>Password changed successfully !</p>
                                    <VerifiedIcon sx={{
                                        fontSize:"150px",
                                        color:"green"
                                    }} />
                                </div>
                            }

                        </div>
                    
                    {
                        !resetSuccess &&
                        <div className="mpuBot">
                        <Button className="mybtn-blue" type="text" loading={load}
                        onClick={submitChangePassword}
                            >
                            Change Password <RightOutlined />
                        </Button>
                        </div>
                    }

                    </div>
                
                </>

            </Modal>

        }
        </>
    );

}

export default Header;