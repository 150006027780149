import Styles from "./flynaut.module.css";
type propType={
    className?:any
}
const Flynaut = ({className}:propType)=>{

    return(
        <>
        <div className={`${Styles.mainCnt} ${className}`}>
            <a href="https://www.ccoconsulting.com/" target="_blank" style={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center"
            }}>
                <img className="logo" src="/images/cco.png" alt="cco_logo" />
            </a>
            <div className={Styles.divider}></div>
            <p className={Styles.devTxt}>Developed By</p>
            <a href="https://flynaut.com" target="_blank" style={{
                height:"auto",
                display:"flex",
                flexDirection:"column",
                textDecoration:"none",
                alignItems:"flex-end",
                justifyContent:"center"
                }}>
                <img className="logo" src="/images/flynaut.png" alt="flynaut_logo" />
                <p className={Styles.partnerTxt}>A <span>Cornerstone</span> Partner Company</p>
            </a>
        </div>
        </>
    )

}

export default Flynaut;