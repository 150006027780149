import React, { ReactElement, useEffect, useRef } from "react";
type props={
    targetElem:any,
    onCloseTrigger:()=> void,
    children?:any
}
const PopupMenu = ({targetElem,onCloseTrigger,children}:props)=>{

    const shareCnt = useRef<any>();

    function getscroll(){
		var b=document.body;
			var d=document.documentElement;
			d=(d.clientHeight)? d : b;
			return d.scrollTop;
	  }

    useEffect(()=>{
        
        setPosition();

    },[])

    const setPosition = ()=>{
        const curr = shareCnt.current;
        // if(!curr) return;

        const bound = targetElem.getBoundingClientRect();
        const elemHeight = targetElem.offsetHeight;
        const elemWidth = targetElem.offsetWidth;
        const elemX = bound.left;
        const elemY = bound.top;
        const cntWidth = curr?.offsetWidth;
        const cntHeight = curr?.offsetHeight;    

        if( ((elemX + elemWidth + cntWidth) > window.innerWidth) && ( cntWidth > (elemX+elemWidth) )){
            curr!.style.left = "0px";
        }
        else if( ((elemX + elemWidth + cntWidth) > window.innerWidth) ){
            curr!.style.left = (elemX-cntWidth!)+"px";
        }
        else{
            curr!.style.left = (elemX+elemWidth)+"px";
        }


        if( bound.top + elemHeight + cntHeight > window.innerHeight){
            curr!.style.top = (elemY-cntHeight!)+"px";
           
        }
        else{
            curr!.style.top = (elemY+elemHeight)+"px";
        }
    }

    const windowClickFunction = (e:MouseEvent)=>{
  
        if (!shareCnt.current?.contains(e.target) && !targetElem.contains(e.target)){
            onCloseTrigger && onCloseTrigger();
        }
  
      }


      useEffect(()=>{
         window.addEventListener('click', windowClickFunction);
         window.addEventListener('resize', setPosition);

        return ()=>{

          window.removeEventListener('click', windowClickFunction);
          window.removeEventListener('resize', setPosition);
        }

      },[])


    return(
        <>
       <div ref={shareCnt} className="popupMenuCnt">
            {children}
        </div>
        </>
    )

}

export default PopupMenu;