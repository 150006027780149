import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Style from "./index.module.css";
import { ReactNode } from 'react';

type propType={
    children:ReactNode
}
const ScrollerWrapper = ({children}:propType)=>{

    function LeftArrow() {
      
        return (
            <div style={{marginRight:"5px"}} className={`${Style.arrowBtn} non-printable`} onClick={(e:any) => {
                let elem = e.target.parentNode.children[1];
                elem.scrollBy({
                    top: 0,
                    left: -200,
                    behavior: "smooth",
                  })
          
            }}>
                <ArrowForwardIosIcon fontSize='small' style={{pointerEvents:"none",transform:"rotate(180deg)"}} />

          </div>
        );
      }
      
      function RightArrow() {
      
        return (
          <div style={{marginLeft:"5px"}} className={`${Style.arrowBtn} non-printable`} onClick={(e:any) => {
            let elem = e.target.parentNode.children[1];
            elem.scrollBy({
                top: 0,
                left: 200,
                behavior: "smooth",
              })
      
        }}>
            <ArrowForwardIosIcon fontSize='small' style={{pointerEvents:"none"}} />
          </div>
        );
      }

    return(
        <div className={Style.scrollWrapper}> 
            <LeftArrow />

            <div className={`${Style.scrollWrapperInner} scroller`}>
                <div className={Style.scrollWrapperContentHolder}>
                    {children}
                </div>
            </div>

            <RightArrow />
        </div>
    )

}

export default ScrollerWrapper;