import { useState } from 'react'

export default function useTouchHandler() {
  
    const [touchTimeOut,setTouchtimeOut] = useState<any>();

    const touchStart:((func?:(()=> void))=> void) = (func)=>{

        setTouchtimeOut(setTimeout(()=>{

            if(func) func();

        },500));

    }

    const touchEnd:(()=> void) = ()=>{
        clearTimeout(touchTimeOut);
    }

    return [touchStart,touchEnd];
}
