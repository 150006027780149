import { CloseOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { useRef, useState } from "react";
import ModalBox from "../modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { removeJob, setActiveJob, updateJobName } from "../../redux/calculatorSlice";
import useTouchHandler from "../../hooks/useTouchHandler";

type propType={
    name:string,
    index:number,
    type:number
}
const JobTab = ({name, index, type}:propType)=>{

    const activeJob = useSelector((state: RootState) => state.calculator.activeJob);
    const allJobs = useSelector((state: RootState) => state.calculator.activeScenarioJobs);
    const dispatch = useDispatch();
    const inp = useRef<any>();
    const [nameEdit,setNameEdit] = useState<boolean>(false);
    const [removeJobAlert,setRemoveJobAlert] = useState(false);
    const [error,setError] = useState("");

    const [touchAndHoldHandler,touchEnd] = useTouchHandler();

    const removeTab = (ind:number)=>{

        let succ = dispatch(removeJob(ind));
        if(succ.payload === 0){
            setError("Cannot remove job because it is open in Split Screen");
        }
    }

    

    return(
        <>
        <div 
        className={`jobTab ${activeJob === index ? "jobTabActive" : "jobTabDeactive"}`}
        onClick={()=> dispatch(setActiveJob(index)) }
        onDoubleClick={()=> !nameEdit && (type === 0 ? setNameEdit(true) : setError("Cannot change name for split screen !")) }
        onTouchStart={()=> touchAndHoldHandler(()=>{ !nameEdit && (type === 0 ? setNameEdit(true) : setError("Cannot change name for split screen !"))  })}
        onTouchEnd={()=> touchEnd() }
        >
        {
            (nameEdit && type === 0) ?
            <Input 
            type="text"
            ref={inp}
            style={{color:"black",minWidth:"180px"}}
            value={name}
            onChange={(e)=> dispatch(updateJobName(e.target.value)) } 
            onBlur={(e)=> {
                if(!e.target.value){
                    dispatch( updateJobName("untitled"))
                }
                setNameEdit(false);
            }}
            onKeyDownCapture={(e)=>{
                if(e.code === "Enter" || e.code === "NumpadEnter"){
                    setNameEdit(false);
                }
            }}
            autoFocus
            />
            :
            <p 
            className="tabName"
            >
                {name}
            </p>
        }
        {
 
            (type === 0) ?
            allJobs.filter(elem=> elem.type === 0).length > 1 &&
            <CloseOutlined 
            className="tabCloseBtn"
            onClick={(event)=> {setRemoveJobAlert(true); event.stopPropagation();} }
            />
            :
            <CloseOutlined 
            className="tabCloseBtn"
            onClick={(event)=> {setRemoveJobAlert(true); event.stopPropagation(); }}
            />
        }
        {
            removeJobAlert &&
            <ModalBox
            open={removeJobAlert}
            onClose={()=> setRemoveJobAlert(false) }
            header={
                type === 0 ?
                "Job Removal !" :
                name
            }
            message={type === 0 ? 
                `Are you sure you want to delete ${name} permanently ?` :
                `Are you sure you want to close this split screen ?`
            } 
            onOk={()=>{
                removeTab(index);
                setRemoveJobAlert(false);
            }}
            onCancel={()=>{
                setRemoveJobAlert(false);
            }}
            />
        }
        {
        error &&
            <ModalBox
            open={error ? true : false}
            onClose={()=> setError("") }
            header="Alert !"
            message={error} 
            onOk={()=>{
                setError("");
            }}
            />
        }
        </div>
        </>
    )

}

export default JobTab;