import React, { ReactElement, useEffect, useState } from "react";

type prop={
    header:string,
    message:ReactElement,
    buttons:ReactElement[],
    open:boolean,
    onClose?:()=> void,
}

const ModalBox2 = ({header,message,buttons,open,onClose}:prop)=>{

    const [active,setActive] = useState(open);

    useEffect(()=>{
        setActive(open);
    },[open])

    const closeHandler = (e:React.MouseEvent<HTMLDivElement>)=>{
        if(onClose){
            setActive(false);
            onClose();
        }
    }

    return(
        <>
        {
            active &&
            <div className="modalBox" onClick={closeHandler}>
            <div className="modalInner" onClick={(e)=> e.stopPropagation() }>
                <div 
                className={`modalHeader `}
                >
                    <p>{header}</p>
            
                </div>
                <div className="modalContent">
                    {message}
                </div>
                <div className="modalBtnCnt">
                  {
                    buttons.map((elem=> (elem)))
                  }
                </div>

            </div>
        </div>
        }
        </>
    )
}

export default ModalBox2;