import { createSlice, current } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { CalculatorType, jobType, scenarioType, singleTabType, submitScenarioType, tabType } from '../@types/user';
import Calculator from '../utils/Calculator';
import NumberFormat from '../utils/NumberFormat';
import PasswordGenerator from '../utils/PasswordGenerator';
import { VERSION } from '../config';

export interface CalculatorState {
  activeTab:number,
  activeScenario:number,
  activeJob:number,
  tabs:tabType,
  activeScreen:scenarioType | null,
  activeTabScenarios:scenarioType[],
  activeScenarioJobs:jobType[],
  time:string
}
interface InsertScenario {
    from:number,
    myname:string,
    mydesc:string
}
interface TextInputType {
  value:string,
  outerIndex:number,
  innerIndex:number,
  lastInpValue?:number
}

interface IdsPayloadType {
    tabId:string,
    ids:number[],
    received:submitScenarioType[]
}

const initialState: CalculatorState = {
  activeTab: 0,
  activeScenario : 0,
  activeJob:0,
  tabs:[],
  activeScreen:null,
  activeTabScenarios:[],
  activeScenarioJobs:[],
  time:Calculator.getRealTime(),
}


export const calculatorSlice = createSlice({
  name: 'calculator',
  initialState,
  reducers: {

    setTimer:(state, action: PayloadAction<string>)=>{
      state.time = action.payload
    },
    setCurrency:(state, action: PayloadAction<string>)=>{

        state.tabs[state.activeTab].scenario[state.activeScenario].currency = action.payload;

        state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
        state.activeTabScenarios = state.tabs[state.activeTab].scenario;
        state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;

    },

    setActiveTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
      state.activeScenario = state.tabs[state.activeTab].activeScenario;
      state.activeJob = state.tabs[state.activeTab].scenario[state.activeScenario].activeJob;

      state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
      state.activeTabScenarios = state.tabs[state.activeTab].scenario;
      state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;
    },

    setActiveScenario : (state, action: PayloadAction<number>)=>{
        state.activeScenario = action.payload;
        state.tabs[state.activeTab].activeScenario = action.payload;
        state.activeJob = state.tabs[state.activeTab].scenario[state.activeScenario].activeJob;

        state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
        state.activeTabScenarios = state.tabs[state.activeTab].scenario;
        state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;
    },
    setActiveJob : (state, action: PayloadAction<number>)=>{
        state.activeJob = action.payload;
        state.tabs[state.activeTab].scenario[state.activeScenario].activeJob = action.payload;

        let splitData = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].splitData || [];
        if(splitData.length > 1){
            let allJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;
            let x = splitData.map((elem,index)=>(
                allJobs[parseInt(elem)].data[0]
            ))
            state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data = x;
        }

        state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
        state.activeTabScenarios = state.tabs[state.activeTab].scenario;
        state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;
    },

    insertTab : (state)=>{

        state.tabs = [...state.tabs,{
            version:VERSION,
            key:PasswordGenerator.getUniqid(16,8),
            tabName:`Tab ${state.tabs.length + 1}`,
            activeScenario:0,
            showResult:false,
            output:[],
            scenario:[
                {
                    key:PasswordGenerator.getUniqid(12,6),
                    name:"Quoted",
                    jobs:[{
                        key:PasswordGenerator.getUniqid(),
                        name:"Job 1",
                        data:[ Calculator.createNewCopyOfCalcFields( Calculator.getCalculatorfields({type:0}) ) ],
                        activeIndex : 0,
                        type:0
                    }],
                    currency:"USD",
                    activeJob:0,
                    type:1
                },
                // {
                //     key:PasswordGenerator.getUniqid(12,6),
                //     name:"Current",
                //     jobs:[{
                //         key:PasswordGenerator.getUniqid(),
                //         name:"Job 1",
                //         data:[ Calculator.createNewCopyOfCalcFields( Calculator.getCalculatorfields({type:0}) ) ],
                //         activeIndex : 0,
                //         type: 0
                //     }],
                //     currency:"dollar",
                //     activeJob:0,
                //     type:0
                // }
            ]
        }]

        state.activeTab = state.tabs.length - 1;
        state.activeScenario = state.tabs[state.activeTab].activeScenario;
        state.activeJob = state.tabs[state.activeTab].scenario[state.activeScenario].activeJob;

        state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
        state.activeTabScenarios = state.tabs[state.activeTab].scenario;
        state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;
    },

    insertScenario : (state, action: PayloadAction<InsertScenario>)=>{
    
        let allScenarios = state.tabs[state.activeTab].scenario;
        
        let index = typeof(action.payload.from) === "string" ? 
        parseInt(action.payload.from) : 
        action.payload.from;

        if(index !== -1){

            let scenarioToCopy = allScenarios[index];
            if(scenarioToCopy){

                let newElem = {...scenarioToCopy};

                newElem.id = "";
                newElem.key = PasswordGenerator.getUniqid(12,6);
                newElem.name = action.payload.myname;
                newElem.type = 1;
                newElem.currency = scenarioToCopy.currency;
                newElem.activeJob = 0;
                newElem.jobs= newElem.jobs.map((elem,index)=>{
                    let x:jobType = {
                        ...elem,
                        key:Math.random.toString(),
                        id:"",
                        data:[ Calculator.createNewCopyOfCalcFields( elem.data[0] ) ],
                        activeIndex:0,
                        type : 0
                    }

                    x.data[0][0].child[0].value = action.payload.mydesc;

                    return x;
                })

                state.tabs[state.activeTab].scenario.push(newElem);
                
            }

        }
        else{
            
            state.tabs[state.activeTab].scenario.push({
                key:PasswordGenerator.getUniqid(12,6),
                name: action.payload.myname,
                jobs:[{
                        key:Math.random().toString(),
                        name:"Job 1",
                        data:[ Calculator.createNewCopyOfCalcFields( Calculator.getCalculatorfields({type:1}) ) ],
                        activeIndex : 0,
                        type: 0
                    }],
                currency:"USD",
                activeJob:0,
                type:1
            })
        }


        state.activeScenario = state.tabs[state.activeTab].scenario.length - 1;
        state.tabs[state.activeTab].activeScenario = state.tabs[state.activeTab].scenario.length - 1;
        state.activeJob = state.tabs[state.activeTab].scenario[state.activeScenario].activeJob;

        state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
        state.activeTabScenarios = state.tabs[state.activeTab].scenario;
        state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;
        
    },
    insertJob : (state, action: PayloadAction<InsertScenario>)=>{
    
        let allJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;
        
        let index = typeof(action.payload.from) === "string" ? 
        parseInt(action.payload.from) : 
        action.payload.from;

        if(index !== -1){

            let jobTocopy = allJobs[index];
            if(jobTocopy){

                let newElem = {...jobTocopy};

                newElem.id = "";
                newElem.key = PasswordGenerator.getUniqid();
                newElem.name = action.payload.myname;
                newElem.data = [ Calculator.createNewCopyOfCalcFields( newElem.data[0] ) ];
                newElem.data[0][0].child[0].value = action.payload.mydesc;
                newElem.type = 0;

                state.tabs[state.activeTab].scenario[state.activeScenario].jobs.push(newElem);
                
            }

        }
        else{

            let elem = {
                key: PasswordGenerator.getUniqid(),
                name: action.payload.myname,
                data:[ Calculator.createNewCopyOfCalcFields( Calculator.getCalculatorfields({type:1}) ) ],
                activeIndex : 0,
                type : 0
            }

            elem.data[0][0].child[0].value = action.payload.mydesc;
            
            state.tabs[state.activeTab].scenario[state.activeScenario].jobs.push(elem);
        }


        state.activeJob = state.tabs[state.activeTab].scenario[state.activeScenario].jobs.length - 1;
        state.tabs[state.activeTab].scenario[state.activeScenario].activeJob = state.activeJob;

        state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
        state.activeTabScenarios = state.tabs[state.activeTab].scenario;
        state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;
        
    },

    removeTab : (state, action: PayloadAction<number>)=>{

      state.tabs.splice(action.payload,1);

      if(state.activeTab === action.payload){
          if(action.payload === 0){
              // setTimeout(()=>{
              //     activatePage();
              // },100)
              state.activeTab = 0;
          }
          else{
              state.activeTab = action.payload - 1;
          }
      }
      else if(action.payload < state.activeTab){
          state.activeTab -= 1;
      }
      state.activeScenario = state.tabs[state.activeTab].activeScenario;
      state.activeJob = state.tabs[state.activeTab].scenario[state.activeScenario].activeJob;

      state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
      state.activeTabScenarios = state.tabs[state.activeTab].scenario;
      state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;

    },
    removeScenario : (state, action: PayloadAction<number>)=>{

        state.tabs[state.activeTab].scenario.splice(action.payload,1);
  
  
        if(action.payload <= state.tabs[state.activeTab].activeScenario){
  
          state.tabs[state.activeTab].activeScenario--;
  
        }

        state.activeScenario =  state.tabs[state.activeTab].activeScenario;
        state.activeJob = state.tabs[state.activeTab].scenario[state.activeScenario].activeJob;

        state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
        state.activeTabScenarios = state.tabs[state.activeTab].scenario;
        state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;
    },
    removeJob : (state, action: PayloadAction<number>)=>{

        let allSplit = state.tabs[state.activeTab].scenario[state.activeScenario].jobs.filter(elem=> elem.type === 1);

        let success = true;

        allSplit.forEach(element => {
            if(element.splitData?.indexOf(action.payload.toString()) !== -1){
                success = false;
            }
        });

        if(success){

            state.tabs[state.activeTab].scenario[state.activeScenario].jobs.splice(action.payload,1);
  
            if(state.activeJob === action.payload){
                if(action.payload === 0){
            
                    state.activeTab = 0;
                }
                else{
                    state.activeJob = action.payload - 1;
                }
            }
            else if(action.payload < state.activeJob){
                state.activeJob -= 1;
            }



            state.tabs[state.activeTab].scenario[state.activeScenario].activeJob = state.activeJob;
  
            state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
            state.activeTabScenarios = state.tabs[state.activeTab].scenario;
            state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;
        }

        action.payload = success ? 1 : 0;

        
    },
    updateTabName : (state, action: PayloadAction<string>)=>{
      state.tabs[state.activeTab].tabName = action.payload;
    },
    
    updateScenarioName : (state, action: PayloadAction<string>)=>{
      state.tabs[state.activeTab].scenario[state.activeScenario].name = action.payload;

      state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
      state.activeTabScenarios = state.tabs[state.activeTab].scenario;
      state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;
    },
    updateJobName : (state, action: PayloadAction<string>)=>{
        state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].name = action.payload;
  
        state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
        state.activeTabScenarios = state.tabs[state.activeTab].scenario;
        state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;
    },

    verifyFormPage: (state, action: PayloadAction<number>)=>{

      if(action.payload < 0) return;

      let calcList:CalculatorType[] = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data;

  
      
      calcList.forEach((outerElem,index) => {

            let diff = action.payload - state.activeScreen?.jobs[state.activeJob].activeIndex!;
            for(let i= state.activeScreen?.jobs[state.activeJob].activeIndex!; (diff > 0 ? i<action.payload : i > action.payload) ; (diff > 0 ? i++ : i-- )){
                let errFound = false;
                let y = outerElem[i].child;

                y.forEach(element => {
                    if(element.type !== "DoublePopup" && element.type !== "popup" && element.type !== "oneScreenPopup"){
                        if(element.required && !element.value){
                            element.error = true
                            errFound = true;
                        }
                        else{
                            element.error = false
                        }
                    }
                    else if(element.type === "DoublePopup"){
                        if(element.value){
                            element.entries?.forEach(outerelem => {
                                outerelem.forEach(innerElem => {
                                    if(!innerElem.value && innerElem.required){
                                        element.error = true
                                        errFound = true;
                                    }
                                    else{
                                        element.error = false
                                    }
                                    
                                });
                            });
                        }
                    }
                    else if(element.type === "oneScreenPopup"){

                        element.dataArr?.forEach(elem => {

                            elem.data.forEach(ielem => {

                                if(ielem.required && !ielem.value){
                                    element.error = true
                                    errFound = true;
                                }
                                else{
                                    element.error = false
                                }
                                
                            });
                            // if(!elem.value){
                            //     element.error = true
                            //     errFound = true;
                            // }
                            // else{
                            //     element.error = false
                            // }
                    });

                    }
                    else{
                        element.data?.forEach(elem => {
                                if(!elem.value){
                                    element.error = true
                                    errFound = true;
                                }
                                else{
                                    element.error = false
                                }
                        });
                    }
                });

                if(errFound){
                    outerElem[i].pageErr = true;
                    outerElem[i].cmp = false;
                }
                else{
                    outerElem[i].pageErr = false;
                    outerElem[i].cmp = true;
                }

            }
            
        });

      if(action.payload <= calcList![0].length - 1){
        state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].activeIndex = action.payload;
      }

      state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data = calcList;

      state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
      state.activeTabScenarios = state.tabs[state.activeTab].scenario;

      let overallErr = false;
      calcList.forEach(element => {
        element.forEach(elem => {
            if(elem.pageErr){
                overallErr = true;
            }
        });
      });
      
      action.payload = overallErr ? 1 : 0;

          
    },
    verifyAllJobs: (state, action: PayloadAction<any>)=>{


        let proceed = true;
        let scName = "";
        let jbName = "";

        for(let i=0; i<action.payload.length; i++){
            let element = current(state.tabs[state.activeTab].scenario[action.payload[i].ind] );

            for(let j=0; j<action.payload[i].jobs.length; j++){
                let elem = element.jobs[ action.payload[i].jobs[j] ];

                for(let k=0; k<elem.data[0].length; k++){
                    let ielem = elem.data[0][k];

                    if(!ielem.cmp){
                        proceed = false;
                        scName = element.name;
                        jbName = elem.name;

                        break;
                    }
                }
                if(!proceed) break;
            }
            if(!proceed) break;
                
        }
  

        action.payload = {scName,jbName,status:proceed};
        

            
      },
    resetFormEntries: (state)=>{
        let p:CalculatorType[] = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data;
        let scenarioType = state.tabs[state.activeTab].scenario[state.activeScenario].type;
  
        let splitData = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].splitData || [];


        let newp = p.map((element,index)=>(
            Calculator.getCalculatorfields({type:scenarioType})
        ));

        splitData.forEach(element => {
            state.tabs[state.activeTab].scenario[state.activeScenario].jobs[parseInt(element)].data = [Calculator.getCalculatorfields({type:scenarioType})];
        });
        
        state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].activeIndex = 0;
        state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data = newp;

        state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
        state.activeTabScenarios = state.tabs[state.activeTab].scenario;
        state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;
    },
    



    updateTextInput:(state, action: PayloadAction<TextInputType>)=>{

      let p:CalculatorType[] = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data;
      let activeIndex = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].activeIndex;

      let splitData = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].splitData;
      let splitIndex = splitData ? parseInt(splitData[action.payload.outerIndex]) : -1;

      let x = p[action.payload.outerIndex];

      x[activeIndex].child[action.payload.innerIndex].value = action.payload.value;
      x[activeIndex].child[action.payload.innerIndex].error = false;
      if(activeIndex === 0 && action.payload.innerIndex === 2){

          if(parseInt(action.payload.value) > 1)
              x[0].child[3].disabled = false
          else
              x[0].child[3].disabled = true
      }
      if(activeIndex === 0 && action.payload.innerIndex === 2){
          x[0].child[3].value = "yes";
          x[1].child[0].value = "";
          x[1].child[0].disabled = false;
          x[1].child[1].value = "";
          x[1].child[1].disabled = false;
          x[1].child[2].value = "";
          x[1].child[2].disabled = false;
          x[1].child[3].value = "";
          x[1].child[3].disabled = false;

      }

      if(activeIndex === 3 && action.payload.innerIndex === 4){
          let perc = (((35/100) * parseInt(action.payload.value)) + (parseInt(action.payload.value))).toFixed(2);
          x[3].child[6].value = perc;
          if(!action.payload.value){
              x[3].child[6].disabled = true;
          }
          else{
              x[3].child[6].disabled = false;
          }
        }

        p[action.payload.outerIndex] = x;
        if(splitIndex !== -1) state.tabs[state.activeTab].scenario[state.activeScenario].jobs[splitIndex].data[0] = x;
        state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data = p;

        state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
        state.activeTabScenarios = state.tabs[state.activeTab].scenario;
        state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;


    },

    changeNumberInput : (state, action: PayloadAction<TextInputType>)=>{
    let p:CalculatorType[] = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data;
    let activeIndex = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].activeIndex;

    let splitData = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].splitData;
    let splitIndex = splitData ? parseInt(splitData[action.payload.outerIndex]) : -1;

      let x = p[action.payload.outerIndex];

      x[activeIndex].child[action.payload.innerIndex].value = action.payload.value;
      x[activeIndex].child[action.payload.innerIndex].error = false;

      if(activeIndex === 0 && action.payload.innerIndex === 2){


          if(parseInt(action.payload.value) > 1)
              x[0].child[3].disabled = false
          else
              x[0].child[3].disabled = true
      }

      if(activeIndex === 0 && action.payload.innerIndex === 2){
          x[0].child[3].value = "yes";
          x[1].child[0].value = "";
          x[1].child[0].disabled = false;
          x[1].child[1].value = "";
          x[1].child[1].disabled = false;
          x[1].child[2].value = "";
          x[1].child[2].disabled = false;
          x[1].child[3].value = "";
          x[1].child[3].disabled = false;
      }

      if(activeIndex === 1 && action.payload.innerIndex === 13){

       if( parseInt(x[1].child[13].value) > 31 )  x[1].child[13].value = "31";
       if( parseInt(x[1].child[13].value) < 1 )  x[1].child[13].value = "1";

      }

      if(activeIndex === 3 && action.payload.innerIndex === 3){

        let percentage = (x[3].child[4].value) ? parseFloat(x[3].child[4].value) : 35;
   
        let perc = (((percentage/100) * parseFloat(action.payload.value)) + (parseFloat(action.payload.value))).toFixed(2);

        x[3].child[5].value = perc !== 'NaN' ? perc : "";
        x[3].child[5].error = perc !== 'NaN' ? false : true;

        if(!action.payload.value){
            x[3].child[5].disabled = true;
        }
        else{
            x[3].child[5].disabled = false;
        }
      }

      if(activeIndex === 3 && action.payload.innerIndex === 4){

        let percentage = (x[3].child[4].value) ? parseFloat(x[3].child[4].value) : 35;
   
          let perc = (((percentage/100) * parseFloat(x[3].child[3].value)) + (parseFloat(x[3].child[3].value))).toFixed(2);
   
          x[3].child[5].value = perc !== 'NaN' ? perc : "";
          if(!x[3].child[3].value){
              x[3].child[5].disabled = true;
          }
          else{
              x[3].child[5].disabled = false;
          }
      }

      if(activeIndex === 4 && action.payload.innerIndex === 3){

        let percentage = (x[4].child[4].value) ? parseFloat(x[4].child[4].value) : 35;
   
        let perc = (((percentage/100) * parseFloat(action.payload.value)) + (parseFloat(action.payload.value))).toFixed(2);

        x[4].child[5].value = perc !== 'NaN' ? perc : "";
        x[4].child[5].error = perc !== 'NaN' ? false : true;

        if(!action.payload.value){
            x[4].child[5].disabled = true;
        }
        else{
            x[4].child[5].disabled = false;
        }
      }

      if(activeIndex === 4 && action.payload.innerIndex === 4){

        let percentage = (x[4].child[4].value) ? parseFloat(x[4].child[4].value) : 35;
   
          let perc = (((percentage/100) * parseFloat(x[4].child[3].value)) + (parseFloat(x[4].child[3].value))).toFixed(2);
   
          x[4].child[5].value = perc !== 'NaN' ? perc : "";
          if(!x[4].child[3].value){
              x[4].child[5].disabled = true;
          }
          else{
              x[4].child[5].disabled = false;
          }
      }

        p[action.payload.outerIndex] = x;
        if(splitIndex !== -1) state.tabs[state.activeTab].scenario[state.activeScenario].jobs[splitIndex].data[0] = x;
        state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data = p;

        state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
        state.activeTabScenarios = state.tabs[state.activeTab].scenario;
        state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;

    },

    blurNumberInput: (state, action: PayloadAction<TextInputType>)=>{

    let p:CalculatorType[] = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data;
    let activeIndex = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].activeIndex;

    let splitData = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].splitData;
    let splitIndex = splitData ? parseInt(splitData[action.payload.outerIndex]) : -1;

      let x = p[action.payload.outerIndex];

      if(activeIndex === 1 && action.payload.innerIndex === 5){
        if(!action.payload.value){
            x[activeIndex].child[action.payload.innerIndex].value = "60";
          }
      }
      if(activeIndex === 1 && action.payload.innerIndex === 6){
          if(!action.payload.value){

              x[activeIndex].child[action.payload.innerIndex].value = "10";
              x[activeIndex].child[action.payload.innerIndex].error = false;
              
          }
      }
      if(activeIndex === 1 && action.payload.innerIndex === 7){
          if(!action.payload.value){
  
              x[activeIndex].child[action.payload.innerIndex].value = "35";
              x[activeIndex].child[action.payload.innerIndex].error = false;
              
          }
      }

      if(activeIndex === 3 && action.payload.innerIndex === 3){

          if(!action.payload.value){
              x[3].child[5].disabled = true;
          }
          else{
              x[3].child[5].disabled = false;
          }
      }

      if(activeIndex === 3 && action.payload.innerIndex === 5){

        if(!action.payload.value){
            let percentage = (x[3].child[4].value) ? parseFloat(x[3].child[4].value) : 35;
   
            let perc = (((percentage/100) * parseFloat(x[3].child[3].value)) + (parseFloat(x[3].child[3].value))).toFixed(2);
            x[3].child[5].value = perc;
        }
    }

    if(activeIndex === 4 && action.payload.innerIndex === 3){

        if(!action.payload.value){
            x[4].child[5].disabled = true;
        }
        else{
            x[4].child[5].disabled = false;
        }
    }

    if(activeIndex === 4 && action.payload.innerIndex === 5){

      if(!action.payload.value){
          let percentage = (x[4].child[4].value) ? parseFloat(x[4].child[4].value) : 35;
 
          let perc = (((percentage/100) * parseFloat(x[4].child[3].value)) + (parseFloat(x[4].child[3].value))).toFixed(2);
          x[4].child[5].value = perc;
      }
  }


      p[action.payload.outerIndex] = x;
      if(splitIndex !== -1) state.tabs[state.activeTab].scenario[state.activeScenario].jobs[splitIndex].data[0] = x;
      state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data = p;

      state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
      state.activeTabScenarios = state.tabs[state.activeTab].scenario;
      state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;

    },
    dropChangeNumberInput: (state, action: PayloadAction<TextInputType>)=>{

    let p:CalculatorType[] = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data;
    let activeIndex = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].activeIndex;

    let splitData = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].splitData;
    let splitIndex = splitData ? parseInt(splitData[action.payload.outerIndex]) : -1;

      let x = p[action.payload.outerIndex];



      let y = x[activeIndex].child[action.payload.innerIndex].secDrop;

        if(y){
            if(activeIndex === 10 && action.payload.innerIndex >=0 && action.payload.innerIndex <=7 ){


                var prevVal:number = (y.value);
                var currVal:number = parseInt(action.payload.value);
                var convertedVal:number=parseInt(x[activeIndex].child[action.payload.innerIndex].value);
    
                if(convertedVal){
     
                  if( prevVal === 0 && currVal === 1 ){
    
                      convertedVal = NumberFormat.weekToMonth(convertedVal);
                  }
                  if( prevVal === 0 && currVal === 2 ){
    
                      convertedVal = NumberFormat.weekToYear(convertedVal);
                  }
                  if( prevVal === 1 && currVal === 0 ){
    
                      convertedVal = NumberFormat.monthToWeek(convertedVal);
                  }
                  if( prevVal === 1 && currVal === 2 ){
    
                      convertedVal = NumberFormat.monthToYear(convertedVal);
                  }
                  if( prevVal === 2 && currVal === 1 ){
    
                      convertedVal = NumberFormat.yearToMonth(convertedVal);
                  }
                  if( prevVal === 2 && currVal === 0 ){
    
                      convertedVal = NumberFormat.yearToWeek(convertedVal);
                  }
    
                
                x[activeIndex].child[action.payload.innerIndex].value = convertedVal.toString();
    
                }

            }

            y.value = parseInt(action.payload.value);
        }



      p[action.payload.outerIndex] = x;
      if(splitIndex !== -1) state.tabs[state.activeTab].scenario[state.activeScenario].jobs[splitIndex].data[0] = x;
      state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data = p;

      state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
      state.activeTabScenarios = state.tabs[state.activeTab].scenario;
      state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;
    },
    boolInputchange: (state, action: PayloadAction<TextInputType>)=>{

        let p:CalculatorType[] = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data;
        let activeIndex = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].activeIndex;

        let splitData = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].splitData;
        let splitIndex = splitData ? parseInt(splitData[action.payload.outerIndex]) : -1;

          let y = p[action.payload.outerIndex];
          let retOb:any ={};


          if(activeIndex ===0 && action.payload.innerIndex === 3){

            if(action.payload.value === "no"){

                let gg = y[activeIndex].child[2];

                if(gg && gg.value > "0"){
                    if(gg){
                        let rr = parseInt(gg.value);

                        if(action.payload.lastInpValue! < rr){
                            var diff = rr-action.payload.lastInpValue!;

                            while(diff !== 0){
                            
                                let nx = gg.fields?.map(elem=>{
                                    return {name:elem.name,value:"",type:elem.type,currency:elem.currency}
                                })

                                gg.entries?.push(nx||[])

                                diff--;
                            }
                        }
                        else if(action.payload.lastInpValue! > rr){
                            var diff = action.payload.lastInpValue!-rr;
                            while(diff !== 0){
                                let f = gg.entries;
                                f?.pop();
                                diff--;
                            }
                        }

                        retOb['lastInpValue'] = rr;
                
                    }

                    retOb['popup2'] = true;
                    retOb['supSub'] = gg.entries;
                    retOb['targetElem'] = gg;

                }
                
            }
            else{
                y[1].child[0].value = "";
                y[1].child[0].disabled = false;
                y[1].child[1].value = "";
                y[1].child[1].disabled = false;
            }
            
        }

        if( (activeIndex===3 && action.payload.innerIndex === 1) || (activeIndex===4 && action.payload.innerIndex === 1)){

            if(action.payload.value === "yes"){

                var myelem = y[activeIndex].child;
                let newelem = {
                    name:"Break Relief Ratio %",
                    type:"num",
                    value:"",
                    error:false,
                    msg:[
                        `In this field, please enter number of break relief operators divided by the total primary operators in the operation being evaluated.`,
                        ` E.g.: If a break relief operator relieves 4 primary operators during the work shift, the percentage would be ¼ or 25%; whereas, if a break relief operator relieves 4 primary operators during the work shift, the percentage would be 1/5 or 20%.`
                    ],
                    required:true,
                    percent:true
                }
                myelem.splice(2,1,newelem);
                
                y[activeIndex].child = myelem;

            }
            else if(action.payload.value=== "no"){
                var myelem = y[activeIndex].child;
                let newelem = {
                    name:"Total Break/Minutes/Shift/ per DL",
                    type:"num",
                    value:"",
                    error:false,
                    msg:[
                        `As the title says, this is the total number of cumulative minutes for both breaks and lunch for the shift.`
                    ],
                    required:true
                }
                myelem.splice(2,1,newelem);
                y[activeIndex].child = myelem;

            }
      
        }

        y[activeIndex].child[action.payload.innerIndex].value = action.payload.value;

        p[action.payload.outerIndex] = y;
        if(splitIndex !== -1) state.tabs[state.activeTab].scenario[state.activeScenario].jobs[splitIndex].data[0] = y;
        state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data = p;

        state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
        state.activeTabScenarios = state.tabs[state.activeTab].scenario;
        state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;

        action.payload.value = JSON.stringify(retOb);


    },
    boolClose:(state, action: PayloadAction<TextInputType>)=>{

    let p:CalculatorType[] = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data;
    let activeIndex = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].activeIndex;

    let splitData = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].splitData;
    let splitIndex = splitData ? parseInt(splitData[action.payload.outerIndex]) : -1;

        let x = p[action.payload.outerIndex];


        x[0].child[3].value = "yes";
        if(x[0].child[2].value > "1")
        x[0].child[3].disabled = false;
        else
        x[0].child[3].disabled = true;


        p[action.payload.outerIndex] = x;
        if(splitIndex !== -1) state.tabs[state.activeTab].scenario[state.activeScenario].jobs[splitIndex].data[0] = x;
        state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data = p;

        state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
        state.activeTabScenarios = state.tabs[state.activeTab].scenario;
        state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;


    },
    boolOk:(state, action: PayloadAction<TextInputType>)=>{


    let p:CalculatorType[] = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data;
    let activeIndex = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].activeIndex;

    let splitData = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].splitData;
    let splitIndex = splitData ? parseInt(splitData[action.payload.outerIndex]) : -1;

      let x = p[action.payload.outerIndex];

      let { res, supLength, error } = JSON.parse(action.payload.value);

      if(error){

        x[0].child[3].value = "yes";
        if(x[0].child[2].value > "1")
        x[0].child[3].disabled = false;
        else
        x[0].child[3].disabled = true;

      }
      else{

          x[1].child[0].value = (res.csp/supLength).toFixed(2);
          x[1].child[0].disabled = true;
          x[1].child[0].error = false;

          x[1].child[1].value = (res.cbc/supLength).toFixed(2);
          x[1].child[1].disabled = true;
          x[1].child[1].error = false;

      }



      p[action.payload.outerIndex] = x;
      if(splitIndex !== -1) state.tabs[state.activeTab].scenario[state.activeScenario].jobs[splitIndex].data[0] = x;
      state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data = p;

      state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
      state.activeTabScenarios = state.tabs[state.activeTab].scenario;
      state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;

    },
    boolInnerInputChange:(state, action: PayloadAction<TextInputType>)=>{

    let p:CalculatorType[] = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data;
    let activeIndex = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].activeIndex;

    let splitData = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].splitData;
    let splitIndex = splitData ? parseInt(splitData[action.payload.outerIndex]) : -1;

      let x = p[action.payload.outerIndex];

      let { targetElem, data, ind, iind } = JSON.parse(action.payload.value);


      let first = x[activeIndex].child[targetElem]?.entries || [];
      first[ind][iind].value = data; 




      x[activeIndex].child[targetElem].entries = first;
      p[action.payload.outerIndex] = x;
      if(splitIndex !== -1) state.tabs[state.activeTab].scenario[state.activeScenario].jobs[splitIndex].data[0] = x;
      state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data = p;

      state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
      state.activeTabScenarios = state.tabs[state.activeTab].scenario;
      state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;
    },
    decisiveInputchange:(state, action: PayloadAction<TextInputType>)=>{

    let p:CalculatorType[] = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data;
    let activeIndex = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].activeIndex;

    let splitData = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].splitData;
    let splitIndex = splitData ? parseInt(splitData[action.payload.outerIndex]) : -1;

      let x = p[action.payload.outerIndex];


        x[activeIndex].child[action.payload.innerIndex].value = action.payload.value;
        x[activeIndex].child[action.payload.innerIndex].error = false;



      p[action.payload.outerIndex] = x;
      if(splitIndex !== -1) state.tabs[state.activeTab].scenario[state.activeScenario].jobs[splitIndex].data[0] = x;
      state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data = p;

      state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
      state.activeTabScenarios = state.tabs[state.activeTab].scenario;
      state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;
    },
    popupInputChange:(state, action: PayloadAction<TextInputType>)=>{

        let p:CalculatorType[] = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data;
        let activeIndex = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].activeIndex;
  
        let splitData = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].splitData;
        let splitIndex = splitData ? parseInt(splitData[action.payload.outerIndex]) : -1;

        let x = p[action.payload.outerIndex];
  
        let { data, nestInd } = JSON.parse(action.payload.value);
  
        x[activeIndex].child[action.payload.innerIndex].data![nestInd].value = data;  
  
  
        p[action.payload.outerIndex] = x;
        if(splitIndex !== -1) state.tabs[state.activeTab].scenario[state.activeScenario].jobs[splitIndex].data[0] = x;
        state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data = p;

        state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
        state.activeTabScenarios = state.tabs[state.activeTab].scenario;
        state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;
    },
    popupInputVerify:(state, action: PayloadAction<TextInputType>)=>{
    let p:CalculatorType[] = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data;
    let activeIndex = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].activeIndex;

    let splitData = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].splitData;
    let splitIndex = splitData ? parseInt(splitData[action.payload.outerIndex]) : -1;

    let x = p[action.payload.outerIndex];

    let { error } = JSON.parse(action.payload.value);

    x[activeIndex].child[action.payload.innerIndex].error = error;



    p[action.payload.outerIndex] = x;
    if(splitIndex !== -1) state.tabs[state.activeTab].scenario[state.activeScenario].jobs[splitIndex].data[0] = x;
    state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data = p;

    state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
    state.activeTabScenarios = state.tabs[state.activeTab].scenario;
    state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;
    },
    doublePopUpFirstSubmit:(state, action: PayloadAction<TextInputType>)=>{
    let p:CalculatorType[] = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data;
    let activeIndex = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].activeIndex;

    let splitData = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].splitData;
    let splitIndex = splitData ? parseInt(splitData[action.payload.outerIndex]) : -1;

    let x = p[action.payload.outerIndex];

    let retOb:any={};

    let ggi = x;
    let gg = ggi[activeIndex].child[action.payload.innerIndex];
    // if(parseInt(action.payload.value) < 1 || !action.payload.value){
    //     gg.value = "";
    //     gg.entries = [];
    //     gg.error = false;
    //     retOb['lastInpvalue'] = 0;
    //     retOb['inpValue'] = "";
    // }
    // else{
        if(parseInt(action.payload.value) > 35){
           retOb['error'] = "Cannot accept more than 35 entries!";

           action.payload.value = JSON.stringify(retOb);
           return;
        }

        gg.value = action.payload.value;
        retOb['inpValue'] = action.payload.value;
        if(gg){
            let rr = parseInt(gg.value);

            if(action.payload.lastInpValue! < rr){
                var diff = rr-action.payload.lastInpValue!;
                while(diff !== 0){
                
                    let nx = gg.fields?.map(elem=>{
                        return {name:elem.name,value:"",type:elem.type,currency:elem.currency,required:elem.required,percent:elem.percent}
                    })

                    gg.entries?.push(nx||[])

                    diff--;
                }
            }
            else if(action.payload.lastInpValue! > rr){
                var diff = action.payload.lastInpValue!-rr;
                while(diff !== 0){
                    let f = gg.entries;
                    f?.pop();
                    diff--;
                }
            }

            retOb['lastInpvalue'] = rr;
    
        }

         if(parseInt(action.payload.value) < 1 || !action.payload.value){
            gg.entries = [];
            gg.error = false;
            retOb['popup2'] = false;

        }
        else{

            retOb['popup2'] = true;
        }
       
        

    // }


    ggi[activeIndex].child[action.payload.innerIndex] = gg;
    x= ggi;

    p[action.payload.outerIndex] = x;
    if(splitIndex !== -1) state.tabs[state.activeTab].scenario[state.activeScenario].jobs[splitIndex].data[0] = x;
    state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data = p;

    state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
    state.activeTabScenarios = state.tabs[state.activeTab].scenario;
    state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;

    action.payload.value = JSON.stringify(retOb);

    },
    doublePopUpInnerInpChange:(state, action: PayloadAction<TextInputType>)=>{

        let p:CalculatorType[] = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data;
        let activeIndex = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].activeIndex;
    
        let splitData = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].splitData;
        let splitIndex = splitData ? parseInt(splitData[action.payload.outerIndex]) : -1;

        let x = p[action.payload.outerIndex];

        let { index, iindex, data } = JSON.parse(action.payload.value);

        x[activeIndex].child[action.payload.innerIndex].entries![index][iindex].value = data;

        
        p[action.payload.outerIndex] = x;
        if(splitIndex !== -1) state.tabs[state.activeTab].scenario[state.activeScenario].jobs[splitIndex].data[0] = x;
        state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data = p;

        state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
        state.activeTabScenarios = state.tabs[state.activeTab].scenario;
        state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;

    },
    doublePopUpSecSubmit:(state, action: PayloadAction<TextInputType>)=>{
        let p:CalculatorType[] = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data;
        let activeIndex = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].activeIndex;
    
        let splitData = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].splitData;
        let splitIndex = splitData ? parseInt(splitData[action.payload.outerIndex]) : -1;

        let x = p[action.payload.outerIndex];


        let {error} = JSON.parse(action.payload.value);

        x[activeIndex].child[action.payload.innerIndex].error = error;

        p[action.payload.outerIndex] = x;
        if(splitIndex !== -1) state.tabs[state.activeTab].scenario[state.activeScenario].jobs[splitIndex].data[0] = x;
        state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data = p;

        state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
        state.activeTabScenarios = state.tabs[state.activeTab].scenario;
        state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;

    },
    oneScreenPopupValueChange:(state, action: PayloadAction<TextInputType>)=>{

        let p:CalculatorType[] = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data;
        let activeIndex = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].activeIndex;
    
        let splitData = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].splitData;
        let splitIndex = splitData ? parseInt(splitData[action.payload.outerIndex]) : -1;

        let x = p[action.payload.outerIndex];

        let { index, iindex, data } = JSON.parse(action.payload.value);

        x[activeIndex].child[action.payload.innerIndex].dataArr![index].data[iindex].value = data;

        
        p[action.payload.outerIndex] = x;
        if(splitIndex !== -1) state.tabs[state.activeTab].scenario[state.activeScenario].jobs[splitIndex].data[0] = x;
        state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].data = p;

        state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
        state.activeTabScenarios = state.tabs[state.activeTab].scenario;
        state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;

    },
    createSplitScreen:(state, action: PayloadAction<string[]>)=>{

        let allJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;
        let name="";
        let data:CalculatorType[] = [];

        action.payload.forEach(element => {
            let elem = allJobs[parseInt(element)];
            if(elem){
                name += name !== "" ? ` - ${elem.name}` : elem.name;
                data.push(elem.data[0])
            }
        });

        let elem:jobType = {
            key:Math.random().toString(),
            name: name,
            data: data,
            activeIndex : 0,
            type : 1,
            splitData : action.payload
        }
        
    

        state.tabs[state.activeTab].scenario[state.activeScenario].jobs.push(elem);


        state.activeJob = state.tabs[state.activeTab].scenario[state.activeScenario].jobs.length - 1;
        state.tabs[state.activeTab].scenario[state.activeScenario].activeJob = state.activeJob;

        state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
        state.activeTabScenarios = state.tabs[state.activeTab].scenario;
        state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;

    },

    getData:(state, action: PayloadAction<string>)=>{
        let x = current(state);

        action.payload = JSON.stringify(x);
    },
    setIds:(state, action: PayloadAction<IdsPayloadType>)=>{

        let { ids, received, tabId } = action.payload;

        state.tabs[state.activeTab].id = tabId;


        //Setting Ids
        received.forEach((element,index) => {

            // For scenarios
            let temp = 0;
            while(state.tabs[state.activeTab].scenario[temp] && state.tabs[state.activeTab].scenario[temp].key !== element.key){
                temp++;
            }
            
            state.tabs[state.activeTab].scenario[temp].id = element.id;


            // for Jobs
            element.jobs.forEach((elem,ind) => {
                let temp2 = 0;
                while(state.tabs[state.activeTab].scenario[temp].jobs[temp2] && state.tabs[state.activeTab].scenario[temp].jobs[temp2].key !== elem.key){
                    temp2++;
                }

                state.tabs[state.activeTab].scenario[temp].jobs[temp2].id = elem.id;
            });
            
        });

        let splitData = state.tabs[state.activeTab].scenario[state.activeScenario].jobs[state.activeJob].splitData || [];

        if(splitData.length > 0){

            let jobs:jobType[] = [];
            splitData.forEach(element => {
                jobs.push( state.tabs[state.activeTab].scenario[state.activeScenario].jobs[parseInt(element)] );
                
            });

            state.tabs[state.activeTab].scenario[state.activeScenario].jobs = jobs;

        }

        state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
        state.activeTabScenarios = state.tabs[state.activeTab].scenario;
        state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;


    },
    newTabwithCustomData : (state, action: PayloadAction<singleTabType>)=>{

        let tabfound = -1;

        state.tabs.forEach((element,index) => {

            if(element.id === action.payload.id){
                tabfound = index;
            }
            
        });

        if(tabfound === -1){
            state.tabs = [...state.tabs, action.payload];
            state.activeTab = state.tabs.length - 1;
        }
        else{
            state.tabs[tabfound] = action.payload;
            state.activeTab = tabfound;
        }
       
        
        state.activeScenario = state.tabs[state.activeTab].activeScenario;
        state.activeJob = state.tabs[state.activeTab].scenario[state.activeScenario].activeJob;

        state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
        state.activeTabScenarios = state.tabs[state.activeTab].scenario;
        state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;

    },
    resetCalc : (state)=>{
        
        state.activeTab = 0;
        state.activeScenario = 0;
        state.activeJob= 0;
        state.tabs= [];
        state.activeScreen= null;
        state.activeTabScenarios= [];
        state.activeScenarioJobs= [];
        state.time= Calculator.getRealTime();

    },
    replaceJobs : (state, action: PayloadAction<{tabIndex:number,sceIndex:number,jbs:jobType[],currency:string}>)=>{

        console.log("frm redux",action.payload.jbs);

        for(let i=0; i< state.tabs[action.payload.tabIndex].scenario[action.payload.sceIndex].jobs.length; i ++){

            for(let j=0; j< action.payload.jbs.length; j++){
                if(action.payload.jbs[j].key === state.tabs[action.payload.tabIndex].scenario[action.payload.sceIndex].jobs[i].key){


                    state.tabs[action.payload.tabIndex].scenario[action.payload.sceIndex].jobs[i].data[0] = action.payload.jbs[j].data[0];
                    break;
                }
            }
        }

        state.tabs[action.payload.tabIndex].scenario[action.payload.sceIndex].currency = action.payload.currency;


     
       
        
        state.activeScreen = state.tabs[state.activeTab].scenario[state.activeScenario];
        state.activeTabScenarios = state.tabs[state.activeTab].scenario;
        state.activeScenarioJobs = state.tabs[state.activeTab].scenario[state.activeScenario].jobs;

    },

  },
})

// Action creators are generated for each case reducer function
export const { 
  setActiveTab,
  setActiveScenario,
  insertTab,
  insertScenario,
  removeTab,
  updateTabName,
  removeScenario,
  updateScenarioName,
  verifyFormPage,
  resetFormEntries,
  setTimer,
  setCurrency,

  updateTextInput,
  changeNumberInput,
  blurNumberInput,
  dropChangeNumberInput,
  boolInputchange,
  boolClose,
  boolOk,
  boolInnerInputChange,
  decisiveInputchange,
  popupInputChange,
  popupInputVerify,
  doublePopUpFirstSubmit,
  doublePopUpInnerInpChange,
  doublePopUpSecSubmit,
  updateJobName,
  insertJob,
  setActiveJob,
  removeJob,
  createSplitScreen,
  oneScreenPopupValueChange,

  getData,
  setIds,
  verifyAllJobs,
  newTabwithCustomData,
  resetCalc,
  replaceJobs

   } = calculatorSlice.actions

export default calculatorSlice.reducer